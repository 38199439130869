import "./index.scss";
import debounce from "lodash/debounce";

function recalculateLayout() {
    // let screenWidth = document.body.getBoundingClientRect().width;
    // const sideBar = document.getElementById("sidebarMenu");
    // if(sideBar && getComputedStyle(sideBar).transform=="none"){
    //   screenWidth-=250;
    // }
    const bounds = document.body.getBoundingClientRect()//height of header
    // console.log("screenHeight", screenHeight)
    // console.log("height, width", screenHeight, screenWidth)

  const gallery = document.getElementById("gallery");
  const main = document.getElementById("main");
  const aspectRatio = 16 / 9;
  const videoCount = document.getElementsByTagName("video").length;
  // const videoControls = document.getElementById("video-controls")
  // const sidebarMenu = document.getElementById("sidebarMenu");

  // or use this nice lib: https://github.com/fzembow/rect-scaler
  function calculateLayout(
    containerWidth: number,
    containerHeight: number,
    videoCount: number,
    aspectRatio: number
  ): { width: number; height: number; cols: number } {
    let bestLayout = {
      area: 0,
      cols: 0,
      rows: 0,
      width: 0,
      height: 0
    };

    // brute-force search layout where video occupy the largest area of the container
    let cols = 1;
    // do{
        const rows = Math.ceil(videoCount / cols);
        const hScale = containerWidth / (cols * aspectRatio);
        const vScale = containerHeight / rows;
        let width;
        let height;
        if (hScale <= vScale) {
        width = Math.floor(containerWidth / cols);
        height = Math.floor(width / aspectRatio);
        } else {
        height = Math.floor(containerHeight / rows);
        width = Math.floor(height * aspectRatio);
        }
        const area = width * height;
        if (area > bestLayout.area) {
            bestLayout = {
                area,
                width,
                height,
                rows,
                cols
            };
        }
        // cols++;
    // } while(!mainVideoCount && cols<=videoCount);
    return bestLayout;
  }

  // let videoHeight; 
  //to check if mobile
  // if(screenWidth<=1024) videoHeight = screenHeight;
  // else videoHeight = screenHeight;

  // const IS_MOBILE = screenWidth<=1024 ? true : false; 
  const MAIN_VIDEO_MULTIPLIER = 1.2;    //magic number
  const GALLERY_MULTIPLIER    = 0.4; //magic number

  // console.log("screen width", screenWidth)

  const { width, height, cols } = calculateLayout(
    bounds.width,
    bounds.height-100,
    1,
    aspectRatio
  );

  // console.log("widht, height, cols", width, height, cols);
  if(gallery){
      gallery.style.setProperty("--width", width*GALLERY_MULTIPLIER + "px");
      gallery.style.setProperty("--height", height*GALLERY_MULTIPLIER + "px");
      gallery.style.setProperty("--cols", cols + "");

  }
  if(main){
    main.style.setProperty("--width", width*MAIN_VIDEO_MULTIPLIER + "px");
    main.style.setProperty("--height", height*MAIN_VIDEO_MULTIPLIER + "px");
    main.style.setProperty("--cols", 1 + "");

    // if(videoControls) videoControls.style.setProperty("--width", width*3 + "px");
  }
  // if(sidebarMenu){
  //   console.log("called")
  //   sidebarMenu.style.property = `transform: translateX(${width})px`;
  // }
}


const debouncedRecalculateLayout = debounce(recalculateLayout, 50);
window.addEventListener("resize", debouncedRecalculateLayout);
debouncedRecalculateLayout();
